$.extend( $.validator.messages, {
    required: getMessage4Validate("m-required"),
    remote: getMessage4Validate("m-remote"),
    email: getMessage4Validate("m-email"),
    url: getMessage4Validate("m-url"),
    date: getMessage4Validate("m-date"),
    dateISO: getMessage4Validate("m-dateISO"),
    number: getMessage4Validate("m-number"),
    digits: getMessage4Validate("m-digits"),
    step: $.validator.format( getMessage4Validate("m-step") ),
    creditcard: getMessage4Validate("m-creditcard"),
    equalTo: getMessage4Validate("m-equalTo"),
    extension: getMessage4Validate("m-extension"),
    maxlength: $.validator.format( getMessage4Validate("m-maxlength") ),
    minlength: $.validator.format( getMessage4Validate("m-minlength") ),
    rangelength: $.validator.format( getMessage4Validate("m-rangelength") ),
    range: $.validator.format( getMessage4Validate("m-range") ),
    max: $.validator.format( getMessage4Validate("m-max") ),
    min: $.validator.format( getMessage4Validate("m-min") ),
    require_from_group: $.validator.format( getMessage4Validate("m-require_from_group") )
} );

$(document).ready(function() {

    jQuery.validator.setDefaults({
        debug: false,
        focusInvalid: false,
        invalidHandler: function(form, validator) {

            if (!validator.numberOfInvalids())
                return;

            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).offset().top - 250
            }, 1000);

        },
    });

    jQuery.validator.addMethod("equal", function (value, element, param) {
        return this.optional(element) || value == param;
    });

    jQuery.validator.addMethod("notEqual", function (value, element, param) {
        return this.optional(element) || value != param;
    });

    jQuery.validator.addMethod("greater", function (value, element, param) {
        return this.optional(element) || value > param;
    });

    jQuery.validator.addMethod("less", function (value, element, param) {
        return this.optional(element) || value < param;
    });

    jQuery.validator.addMethod("validDate", function(value, element) {
        var _date= value.substring(6, 10) + "-" + value.substring(3, 5) + "-" + value.substring(0, 2);
        return this.optional(element) || dateValid(_date);
    });

    jQuery.validator.addMethod("afterToday", function(value, element, params) {

        var day = value.substring(0, 2) * 1;
        var month = value.substring(3, 5) * 1 - 1;
        var year = value.substring(6, 10) * 1;
        var _date = new Date(year, month, day);


        var _n_date = new Date();
        _n_date.setDate(_n_date.getDate() - 1);

        return _n_date < _date;
    });

    jQuery.validator.addMethod("afterDate", function(value, element, params) {
        var day = value.substring(0, 2) * 1;
        var month = value.substring(3, 5) * 1 - 1;
        var year = value.substring(6, 10) * 1;
        var _date = new Date(year, month, day);

        if (params !== '') {
            var _startDate = $("#" + params).val();
            var _day = _startDate.substring(0, 2) * 1;
            var _month = _startDate.substring(3, 5) * 1 - 1;
            var _year = _startDate.substring(6, 10) * 1;
            var __startDate = new Date(_year, _month, _day);
        } else {
            return true;
            // if param is empty there is no need for compare
        }

        //_date = stringToDate(value);
        //__startDate = stringToDate(params);

        //console.log("afterDate ### __startDate=> " + _startDate + " _date=> " + value );
        //console.log("afterDate ### __startDate=> " + __startDate + " _date=> " + _date );

        //var __startDate = $("#" + params).val();
        //return __startDate <= _date || value == "";
        return __startDate <= _date;
    });
    jQuery.validator.addMethod("beforeDate", function(value, element, params) {
        var day = value.substring(0, 2) * 1;
        var month = value.substring(3, 5) * 1 - 1;
        var year = value.substring(6, 10) * 1;
        var _date = new Date(year, month, day);

        if (params !== '') {
            var _startDate = $("#" + params).val();
            var _day = _startDate.substring(0, 2) * 1;
            var _month = _startDate.substring(3, 5) * 1 - 1;
            var _year = _startDate.substring(6, 10) * 1;
            var __startDate = new Date(_year, _month, _day);
        }else{
            return true;
            // if param is empty there is no need for compare
        }

        //_date = stringToDate(value);
        //__startDate = stringToDate(params);

        //console.log("beforeDate ### __startDate=> " + _startDate + " _date=> " + value );
        //console.log("beforeDate ### __startDate=> " + __startDate + " _date=> " + _date );
        //var __startDate = $("#" + params).val();
        //return __startDate >= _date || value == "";
        return __startDate >= _date;
    });

    jQuery.validator.addMethod("notEqualTwoParameters", function (value, element, params) {
        return params.param1 != params.param2;
    });

    jQuery.validator.addMethod("parameterOneBiggerThanParameterTwo", function (value, element, params) {
        return params.param1 > params.param2;
    });

    jQuery.validator.addMethod("aValueBetweenTwoParameters", function (value, element, params) {
        return params.param1 > params.param2 ||  params.param1 < params.param3;
    });

    jQuery.validator.addMethod("aCustomValid", function (value, element, params) {
        return params;
    });

    jQuery.validator.addMethod("anOtherCustomValid", function (value, element, params) {
        return params;
    });

    jQuery.validator.addMethod("thirdCustomValid", function (value, element, params) {
        return params;
    });

    jQuery.validator.addMethod("betweenTwoParameters", function (value, element, params) {
        return  params.param1 > value && value < params.param2;
    });
});